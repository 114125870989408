import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteProjectImage, listProjectDetails, updateProject } from '../../actions/projectActions'
import Photos from '../../components/Photos'
import { PROJECT_UPDATE_RESET } from '../../constants/projectConstants'
import AdminLayout from './AdminLayout'

function ProjectEditScreen({match,history}) {
    const projectId = match.params.id

    const [title, setTitle] = useState("")
    const [client, setClient] = useState("")
    const [description, setDescription] = useState("")
    const [city, setCity] = useState("")
    const [country, setCountry] = useState("")

    const [images, setImages] = useState([])

    const dispatch = useDispatch()

    const projectUpdate = useSelector((state) => state.projectUpdate)
    const {
      loading: loadingUpdate,
      error: errorUpdate,
      success: successUpdate,
    } = projectUpdate

    const projectDetails = useSelector((state) => state.projectDetails)
    const { loading, error, project } = projectDetails
 

    useEffect(() => {
        if (successUpdate) {
          dispatch({ type: PROJECT_UPDATE_RESET })
          history.push('/admin/projectlist')
        } else {
          if (!project.title || project._id !== projectId) {
            dispatch(listProjectDetails(projectId))
          } else {
            setTitle(project.title)
            setClient(project.client)
            setCity(project.client)
            setCountry(project.country)
            setCity(project.city)
            setDescription(project.description)
        
          }
        }
      }, [dispatch, history, projectId, project, successUpdate])
    

      const submitHandler = (e) => {
      
        e.preventDefault()

        dispatch(
          updateProject({
            _id: projectId,
            title,
            client,
            country,
            city,
            description
          },images)
        )
       
      }
      

    return (
        <>
          <AdminLayout>
            <h1 className='text-xl font-semibold'> Edit Project {title}</h1>
            {loadingUpdate ? 
            "loading ":
              errorUpdate ?
              "errro"+
              errorUpdate:
              ""
            }
            <div className='pt-4'>
              <form >
                  <Photos deleteImage={deleteProjectImage} images={project?.images} id={project._id} setImages={setImages} />
                  <div className='pt-3'>
                      <label htmlFor="title">Title</label>
                      <div className='border rounded-md'>
                          <input type="text" value={title} onChange={(e)=> setTitle(e.target.value)} className='p-2 w-full outline-none bg-transparent ' placeholder='Title ' />
                      </div>
                  </div>
                  <div className='pt-3'>
                      <label htmlFor="client">Client</label>
                      <div className='border rounded-md'>
                          <input type="text" value={client} onChange={(e)=> setClient(e.target.value)} className='p-2 w-full outline-none bg-transparent ' placeholder='Client ' />
                      </div>
                  </div>
                  <div className='pt-3'>
                      <label htmlFor="country">Country</label>
                      <div className='border rounded-md'>
                          <input type="text" value={country} onChange={(e)=> setCountry(e.target.value)} className='p-2 w-full outline-none bg-transparent ' placeholder='Title ' />
                      </div>
                  </div>
                  <div className='pt-3'>
                      <label htmlFor="country">City</label>
                      <div className='border rounded-md'>
                          <input type="text" value={city} onChange={(e)=> setCity(e.target.value)} className='p-2 w-full outline-none bg-transparent ' placeholder='Title ' />
                      </div>
                  </div>
                  <div className='pt-3'>
                      <label htmlFor="Description">Description</label>
                      <div className='border rounded-md'>
                          <textarea type="text" value={description} onChange={(e)=> setDescription(e.target.value)} className='p-2 w-full outline-none bg-transparent ' placeholder='Title ' />
                      </div>
                  </div>

                  <div className='pt-3'>
                      <button onClick={submitHandler} className='p-2 rounded-md bg-black text-white'>Update Product</button>
                  </div>
              </form>
            </div>

            
          </AdminLayout>
            
        </>
    )
}

export default ProjectEditScreen
