import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteProduct } from '../../actions/productActions'
import { createProject, deleteProject, listProjects } from '../../actions/projectActions'
import Loader from '../../components/Loader'
import Message from '../../components/Message'
import Paginate from '../../components/Paginate'
import { PROJECT_CREATE_RESET } from '../../constants/projectConstants'
import AdminLayout from './AdminLayout'

function ProjectListScreen({history}) {

    const userLogin = useSelector((state) => state.userLogin)
    const { userInfo } = userLogin  
    
    const {projects,page,pages,loading,error} = useSelector(state => state.projectList)

    const projectCreate = useSelector((state) => state.projectCreate)
    const {
      loading: loadingCreate,
      error: errorCreate,
      success: successCreate,
      project: createdProject
    } = projectCreate

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(listProjects())
    }, [dispatch])

   

    
  useEffect(() => {
    dispatch({ type: PROJECT_CREATE_RESET })

    if (!userInfo || !userInfo.isAdmin) {
      history.push('/login')
    }

    if (successCreate) {
        console.log(createdProject)
      history.push(`/admin/project/${createdProject._id}/edit`)
    } else {
      dispatch(listProjects())
    }
  }, [
        dispatch,
        history,
        userInfo,
        successCreate,
        createdProject
        
    ])

    const createProjectHandler = () => {
        dispatch(createProject())
    }

    const deleteHandler = (id)=> {
        if (window.confirm('Are you sure')) {
            dispatch(deleteProject(id))
        }
      
    }

    return (
        <>
            <AdminLayout>
                <div className="flex">
                
                    <div className="w-full pl-1">
                    <div className='flex justify-between py-3  items-center align-items-center'>
                        <div>
                        <h1 className='text-xl font-semibold'>Projects</h1>
                        </div>
                        <div >
                        <button className=' bg-black text-white py-1 px-2 ' onClick={createProjectHandler}>
                            Create Project
                        </button>
                        </div>
                    </div>
                        {loading ? (
                        <Loader />
                        ) : error ? (
                            <Message variant='danger'>{error}</Message>
                        ) : (
                        <>
                        
                        {projects.map((project,id) => (
                            <div className='flex justify-between p-3' key={project.reference}>
                            <div className='flex'>
                                <div className='w-32 relative'>
                                <img className='w-full object-cover' src={project.images[0].image} alt="" />
                                <div className='absolute right-0 bottom-0 bg-black p-1 flex justify-end bg-opacity-10 text-white'>
                                    + {project.images.length}
                                </div>
                                </div>
                                <div className='px-4'>
                                    <div className='text-xl'>{project.title}</div>
                                    <div className='text-sm font-thin opacity-8 uppercase0'>Client : {project.client}</div>
                                </div>
                            
                            </div>

                            <div className='actions '>
                                <button className="bg-primary text-white uppercase px-2 py-1 mr-1 rounded-md" onClick={()=> history.push(`/admin/project/${project._id}/edit`)}>
                                    edit
                                </button>
                                <button className="bg-primary text-white uppercase px-2 py-1 mr-1 rounded-md"
                                    onClick={() => deleteHandler(project._id)}
                                >
                                    delete
                                </button>
                            </div>
                            
                            </div>
                        ))}
                    
                        <Paginate pages={pages} page={page} isAdmin={true} />
                        </>
                        )} 
                    </div>

                </div>
            </AdminLayout>
        </>
    )
}

export default ProjectListScreen
