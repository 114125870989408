import React, { useEffect, useState } from 'react'

function Filters({history,page,pages}) {

    const [filterData, setFilterData] = useState({city:"",category:"",minPrice:"",maxPrice:""})

    const filterCategory = (e) => {
        history.push(`/listing/search/${e.target.value}-${filterData.city}-${filterData.minPrice}-${filterData.maxPrice}`)
        setFilterData({...filterData,category:e.target.value})
    }

    const filterCity = (e) => {    
        history.push(`/listing/search/${filterData.category}-${e.target.value}-${filterData.minPrice}-${filterData.maxPrice}`) 
        setFilterData({...filterData,city:e.target.value})
    }
    const filterMinPrice = (e) => {
        history.push(`/listing/search/${filterData.category}-${e.target.value}-${e.target.value}-${filterData.maxPrice}`) 
        setFilterData({...filterData,minPrice:e.target.value})
    }
    const filterMaxPrice = (e) => {
        history.push(`/listing/search/${filterData.category}-${e.target.value}-${filterData.minPrice}-${e.target.value}`) 
        setFilterData({...filterData,maxPrice:e.target.value})
    }
    
    // useEffect(() => {
        
    //     if (pages > 1) history.push(`/listing/search/${filterData.category}-${filterData.city}-${filterData.minPrice}-${filterData.maxPrice}/page/${page}`)
    //     else history.push(`/listing/search/${filterData.category}-${filterData.city}-${filterData.minPrice}-${filterData.maxPrice}`)
    // }, [filterData])
    


    return (
        <div>
            <div className='py-8 flex justify-between'>

                <div>
                    <div className='flex items-center '>
                        Find :  
                        <select  onChange={filterCategory} className='w-32 ml-2 p-2 border rounded-md outline-none' name="" id="">
                            <option  value="Select category" >All</option>
                            <option className='' value="Villas"> For Sale</option>
                            <option className='' value="Apartments"> For Rent</option>
                            <option className='' value="Apartments"> New Home</option>

                        </select>
                        
                    </div>
                </div>
                <div className='flex flex-wrap gap-2 justify-center items-center'>
                    
                    <div className='flex '>
                    
                        <select  onChange={filterCategory} className='w-32 p-2 border rounded-md outline-none' name="" id="">
                            <option className=' opacity-70' value="Select category" disabled>Category</option>
                            <option className='' value="Villas"> Villas</option>
                            <option className='' value="Apartments"> Apartments</option>
                        </select>
                        
                    </div>


                    <div className='flex '>
                        <select  onChange={filterCity} className='w-32 p-2 border rounded-md outline-none' name="" id="">
                        <option className=' opacity-70' value="Select category" disabled>City</option>
                        <option className='' value="Marrakech"> Marrakech</option>
                        <option className='' value="Rabat"> Rabat</option>
                        <option className='' value="Casablanca"> Casablanca</option>
                        </select>
                        
                    </div>

                    <div className='flex '>
                       <select onChange={filterMinPrice}  className='w-32 p-2 border rounded-md outline-none' name="" id="">
                            <option className=' opacity-70' value="Select category" hidden>Min Price</option>
                            <option className='' value="0"> 0</option>
                            <option className='' value="200"> 200</option>
                            <option className='' value="230000"> 230000</option>
                            <option className='' value="240000"> 240000</option>
                            <option className='' value="250000"> 250000</option>
                        </select>
                        
                    </div>

                    <div className='flex '>
                       <select  onChange={filterMaxPrice} className='w-32 p-2 border rounded-md outline-none' name="" id="">
                            <option className=' opacity-70' value="Select category" hidden>Max Price</option>
                            <option className='' value="200000"> 200000</option>
                            <option className='' value="220000"> 220000</option>
                            <option className='' value="230000"> 230000</option>
                            <option className='' value="240000"> 240000</option>
                            <option className='' value="250000"> 250000</option>
                        </select>
                        
                    </div>
                    
                   

                </div>


            </div>
        </div>
    )
}

export default Filters