import React from 'react'
import Contact from '../components/Home/Contact'
import Slider from '../components/Home/Slider'
import Services from '../components/Home/Services'



function Home() {
  return (
    <div className='min-h-screen w-full bg-black text-white '>
     
        <Slider />
        <Services /> 
        {/* <LastHomes /> */}
        <Contact />
    </div>
  )
}

export default Home