import React, { useEffect } from 'react'
import { BrowserRouter as Router, Link, Route } from 'react-router-dom'
import ScrollToTop from './components/ScrollToTop'
import AOS from "aos";
import "aos/dist/aos.css";
import Home from './screens/Home';
import Header from './components/Header';
import Footer from './components/Footer';
import ProductsListScreen from './screens/ProductsListScreen';
import ProductScreen from './screens/ProductScreen';
import Projects from './screens/Projects';
import ProjectDetail from './screens/ProjectDetail';
import UserListScreen from './screens/admin/UserListScreen';
import ProductListScreen from './screens/admin/ProductListScreen';
import OrderListScreen from './screens/admin/OrderListScreen';
import ProductEditScreen from './screens/admin/ProductEditScreen';
import ContactUs from './components/Home/ContactUs';
import Dashboard from './screens/admin/Dashboard';
import ProjectListScreen from './screens/admin/ProjectListScreen';
import ProjectEditScreen from './screens/admin/ProjectEditScreen';
import LoginScreen from './screens/LoginScreen';
import RegisterScreen from './screens/RegisterScreen';

const App = () => {
  
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  
  return (
    <div >
      
    <Router>
      <ScrollToTop />
      <Header />

        <Route path={"/"} exact component={Home} /> 
        <Route path={"/login"} exact component={LoginScreen} /> 
        <Route path={"/register"} exact component={RegisterScreen} /> 


        <Route path={"/contact-us"}  component={ContactUs} /> 
        <Route path={"/products"} exact component={ProductsListScreen} /> 
        <Route path={"/products/:id"} exact component={ProductScreen} /> 
        
        <Route path={"/products/search/:keyword"}  component={ProductsListScreen} /> 
        <Route path={"/projects"} exact component={Projects} /> 
        <Route path={"/projects/:id"} component={ProjectDetail} /> 
        
      {/* <Route path={"/contact-us"}  component={ContactUs} /> 

        <Route path={"/projects"} exact component={Projects} /> 
        <Route path={"/projects/:id"} component={ProjectDetail} /> 


        <Route path={"/listing"} exact component={Listing} /> 
        <Route path={"/listing/:id"} exact component={ListingDetail} /> 
        
        <Route path={"/listing/search/:keyword"}  component={Listing} />
        <Route path={`/admin/userlist`} component={UserListScreen}/>
        <Route path={`/admin/productlist/:pageNumber`} exact component={ProductListScreen}/>
        
        <Route path={`/admin/productlist`} exact component={ProductListScreen}/>
        <Route path={`/admin/orderlist`} component={OrderListScreen}/>
        <Route path={`/admin/product/:id/edit`} component={ProductEditScreen}/> */}
        
        <Route path={`/admin/`} exact component={Dashboard}/>

        <Route path={`/admin/userlist`} component={UserListScreen}/>
        <Route path={`/admin/productlist/:pageNumber`} exact component={ProductListScreen}/>
        
        <Route path={`/admin/projectlist`} exact component={ProjectListScreen}/>
        <Route path={`/admin/project/:id/edit`} exact component={ProjectEditScreen}/>
        <Route path={`/admin/productlist`} exact component={ProductListScreen}/>
        <Route path={`/admin/orderlist`} component={OrderListScreen}/>
        <Route path={`/admin/product/:id/edit`} component={ProductEditScreen}/> 
      <Footer />
    </Router>
  
    </div>
    )
}

export default App
