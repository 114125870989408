import React from 'react'

function Contact() {
  return (
    <div className='bg-gray-50 text-black flex justify-center text-center items-center py-20'>
        <div>
            
            <h1 className='text-4xl pb-4 '>Get in touch </h1>
            
           
            <p>We look forward to realise extraordinary projects with you.</p>

            <div className='pt-4 flex flex-col md:flex-row items-center gap-2'>

                <div className='w-44 p-2 border'>
                    HS contacts
                </div>
                <a href='/contact-us'>
                    <div className='w-44 p-2 border'>
                        Email us
                    </div>
                </a>
       

            </div>

        </div>
    </div>
  )
}

export default Contact