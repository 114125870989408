import React from 'react'

const Message = ({ variant, children }) => {
  return <div className={`p-2 w-full  ${variant==="info" ? 'bg-gray-100':variant==="danger"?"bg-red-300":variant==="sucess"?"bg-green-300":""}`}>{children}</div>
}

Message.defaultProps = {
  variant: 'bg-gray-100 ',
}

export default Message
