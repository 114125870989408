import React from 'react'
import AdminLayout from './AdminLayout'

function Dashboard() {
  return (
    <AdminLayout>
        <div>Dashboard</div>
    </AdminLayout>
    
  )
}

export default Dashboard