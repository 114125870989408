import React from 'react'

function PopUp({children,isOpen,setIsOpen}) {
  return (
    <div  className={` fixed top-0 left-0 z-60 w-screen h-screen bg-black/70 flex justify-center items-center ${isOpen ? "block":"hidden"}`}>
        <div className='absolute left-1/2 top-[80%] md:top-[60%] transform -translate-y-1/2 z-100 h-full py-8 lg:w-full w-11/12 max-w-5xl ' style={{transform:"translate3d(-50%, -50%, 0px)"}}>
            <div onClick={()=>setIsOpen(!isOpen)} className='absolute top-0 cursor-pointer bg-gray-200 py-2 px-4 right-0'>
                Close
            </div>
            <div className='pt-'>
                {children}
            </div>
        </div>
       
    </div>
  )
}

export default PopUp