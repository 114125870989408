import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../../components/Message'
import Loader from '../../components/Loader'
import Paginate from '../../components/Paginate'
import {
  listProducts,
  deleteProduct,
  createProduct,
} from '../../actions/productActions'
import { PRODUCT_CREATE_RESET } from '../../constants/productConstants'
import { Link } from 'react-router-dom'
import SideBar from '../../components/SideBar'
import AdminLayout from './AdminLayout'

const ProductListScreen = ({ history, match }) => {
  const pageNumber = match.params.pageNumber || 1

  
  const [showModalActions, setShowModalActions] = useState(false)

  const dispatch = useDispatch()

  const productList = useSelector((state) => state.productList)
  const { loading, error, products, page, pages } = productList

  const productDelete = useSelector((state) => state.productDelete)
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = productDelete

  const productCreate = useSelector((state) => state.productCreate)
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
    product: createdProduct
  } = productCreate

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    dispatch({ type: PRODUCT_CREATE_RESET })

    if (!userInfo || !userInfo.isAdmin) {
      history.push('/login')
    }

    if (successCreate) {
      history.push(`/admin/product/${createdProduct._id}/edit`)
    } else {
      dispatch(listProducts("", pageNumber))
    }
  }, [
    dispatch,
    history,
    userInfo,
    successDelete,
    successCreate,
    createdProduct,
    pageNumber,
  ])

  const deleteHandler = (id) => {
    if (window.confirm('Are you sure')) {
      dispatch(deleteProduct(id))
    }
  }

  const createProductHandler = () => {
    dispatch(createProduct())
  }

  const showActions = () =>{
    setShowModalActions(!showModalActions)
  }

  return (
    
   <AdminLayout>
      <div className="flex">
    
        <div className="w-full pl-1">
          <div className='flex justify-between py-3  items-center align-items-center'>
            <div>
              <h1 className='text-xl font-semibold'>Products</h1>
            </div>
            <div >
              <button className=' bg-black text-white py-1 px-2 ' onClick={createProductHandler}>
                Create Product
              </button>
            </div>
          </div>
            {loadingDelete && <Loader />}
            {errorDelete && <Message variant='danger'>{errorDelete}</Message>}
            {loadingCreate && <Loader />}
            {errorCreate && <Message variant='danger'>{errorCreate}</Message>}
            {loading ? (
              <Loader />
            ) : error ? (
                <Message variant='danger'>{error}</Message>
            ) : (
              <>
            
              {products.map((product,id) => (
                <div className='flex justify-between p-3' key={product.reference}>
                  <div className='flex'>
                    <div className='w-32 relative'>
                     <img className='w-full object-cover' src={product?.images[0]?.image} alt="" />
                     <div className='absolute right-0 bottom-0 bg-black p-1 flex justify-end bg-opacity-10 text-white'>
                        + {product?.images?.length}
                     </div>
                    </div>
                    <div className='px-4'>
                      <div className='text-xl'>{product.name}</div>
                      <div className='text-sm font-thin opacity-8 uppercase0'>Price : {product.price}</div>
                      <div className='text-sm font-thin opacity-80'>Category : {product.category}</div>
                    </div>
                  
                  </div>

                  <div className='actions '>
                      <button className="bg-primary text-white uppercase px-2 py-1 mr-1 rounded-md" onClick={()=> history.push(`/admin/product/${product._id}/edit`)}>
                        edit
                      </button>
                      <button className="bg-primary text-white uppercase px-2 py-1 mr-1 rounded-md"
                        onClick={() => deleteHandler(product._id)}
                      >
                        delete
                      </button>
                  </div>
                
                </div>
              ))}
        
              <Paginate pages={pages} page={page} isAdmin={true} />
            </>
            )} 
        </div>

      </div>
   </AdminLayout>

  )
}

export default ProductListScreen
