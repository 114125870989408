import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { listprojects } from '../actions/productActions'
import { listProjects } from '../actions/projectActions'

function Projects({match,history}) {

    const pageNumber = match.params.pageNumber || 1
    const keyword = match.params.keyword || ""

    const dispatch = useDispatch()
    const { loading, error, projects, page, pages } = useSelector((state) => state.projectList)

    console.log(projects)
    
    useEffect(() => {
        dispatch(listProjects(keyword,pageNumber))
    }, [dispatch,pageNumber,keyword])


    return (
        <div >
            <div className='h-56 pb-10 flex flex-col items-center justify-end bg-black text-white'>
                <h1 className='text-3xl uppercase py-3'>Our Projects </h1>
                <p className='text-sm opacity-80'> Lorem ipsum dolor sit amet consectetur adipisicing elit. Ea, repellendus! </p>
            </div>

            <div className='py-20 flex items-center justify-center'>
                <div className='w-1/2 max-w-md mx-auto'>
                    <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Blanditiis inventore ad reprehenderit dolorem repellat cum fugit tempora rerum laudantium officia praesentium sequi, ducimus velit! Dolorem, ad amet. Minus, rem ut.
                    </p>
                <p className='pt-4'>
                        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Dolore magnam itaque, consectetur minima voluptates veritatis. Delectus ad qui nihil, sed ullam accusantium blanditiis debitis eius ex alias tenetur rem iure molestias iste eum, consequuntur aperiam eveniet consectetur! Est illo eum itaque, quasi dolore odit modi dolorem, architecto sunt numquam dolores rem incidunt veritatis id! Iure fuga illo quis provident molestias?   
                    </p>
                </div>
                <div className='w-1/2 max-w-md mx-auto'>
                    <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Blanditiis inventore ad reprehenderit dolorem repellat cum fugit tempora rerum laudantium officia praesentium sequi, ducimus velit! Dolorem, ad amet. Minus, rem ut.
                    </p>
                    <p className='pt-4'>
                        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Dolore magnam itaque, consectetur minima voluptates veritatis. Delectus ad qui nihil, sed ullam accusantium blanditiis debitis eius ex alias tenetur rem iure molestias iste eum, consequuntur aperiam eveniet consectetur! Est illo eum itaque, quasi dolore odit modi dolorem, architecto sunt numquam dolores rem incidunt veritatis id! Iure fuga illo quis provident molestias?   
                    </p>
                </div>
            </div>


            <div className='pt-10'>
                <div className='max-w-screen-xl mx-auto px-2'>
                    <div className='collection'>

                        {projects?.map(pr =>
                            <div className='item relative' key={pr._id}>
                                <Link to={`/projects/${pr._id}`}>
                                    <img src={pr.images[0]?.image}  />
                                </Link>
                                
                                <div className='absolute p-2 bg-black bg-opacity-40 bottom-5 text-white right-5'>
                                    <h1 className='text-xl font-bold'>
                                       {pr.title}
                                    </h1>
                                    <p>
                                      {pr.decription}
                                    </p>
                                </div>
        
                            </div>
                            )}

                            

        
                    </div> 


                </div>
            </div>
        </div>
    )
}

export default Projects