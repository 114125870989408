import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { listProducts } from '../actions/productActions'
import Filters from '../components/Filters'
import Loader from '../components/Loader'
import Message from '../components/Message'
import Product from '../components/Product'
import Paginate from '../components/Paginate'



function ProductsListScreen({match,history}) {
    const pageNumber = match.params.pageNumber || 1
    const keyword = match.params.keyword || ""
    console.log(keyword)
    const dispatch = useDispatch()
    const { loading, error, products, page, pages } = useSelector((state) => state.productList)

    useEffect(() => {
      dispatch(listProducts("", pageNumber))
    }, [dispatch,pageNumber,keyword])


    return (
        <div className="pb-20" >

            <div className='h-56 pb-10 flex flex-col items-center justify-end bg-black text-white'>
                <h1 className='text-3xl uppercase pb-2'>Listing  </h1>
                <p className='text-sm opacity-80'> Lorem, ipsum dolor sit amet consectetur adipisicing elit. Adipisci, sed!</p>
            </div>
            {loading ? (
            <Loader />
            ) : error ? (
            <Message variant='danger'>{error}</Message>
            ) : (
            <div className='min-h-screen max-w-screen-xl px-4 mx-auto'>

            
                <Filters 
                    history={history}
                    page={page}
                    pages={pages}
                    />
            
        
                <div className="grid lg:grid-cols-3 2xl:grid-cols-3 md:grid-cols-2 grid-cols-1 lg:gap-x-6 gap-x-4 lg:gap-y-2 gap-y-1  lg:col-span-3">
                    {products?.map((product) => (
                         <Product key={product._id}  product={product} />
                    ))}
                </div>
                <Paginate
                    pages={pages}
                    page={page}
                    keyword={keyword ? keyword : ''}
                />
                {pages <1 ? <div className='pt-10 max-w-md mx-auto'>
                    Désolé! Aucun résultat ne
                    correspond à votre recherche
                </div>:''}
                <div>
                {/* <h1>Here put some content</h1> */}
                </div>
            </div>
            )}
        
        </div>
    )
}

export default ProductsListScreen
