import React from 'react'
import SideBar from '../../components/SideBar'

function AdminLayout(props) {
  return (
    <div className='flex p-4'>
        <div className='flex-none w-52 relative '>
            <SideBar />
        </div>
      
        <div className='flex-grow'>
            {props.children}
        </div>
    </div>
  )
}

export default AdminLayout