import React, { useState } from 'react'

function Slider() {
    const [isOpen, setIsOpen] = useState(false)

    const [currentImage, setCurrentImage] = useState(1)

    const clickPrev =  () => {
        if( currentImage >1){
            setCurrentImage(currentImage-1)
        }
        
    }
    const clickNext =  () => {
        if (currentImage <=3 ){
            setCurrentImage(currentImage+1)
        }
       

    }


    return (
        <div>
          <div className=' h-[96vh]  w-full'>
                <div onMouseLeave={()=> setIsOpen(false)} onMouseOver={()=>setIsOpen(true)} className='relative bg-cover bg-center bg-no-repeat  h-[96vh]  w-full' style={{backgroundImage:`url(./images/slider/${currentImage}.jpg)`}}>
              

                    <div className='absolute overflow-hidden bg-theme text-white bg-opacity-70 max-w-xl text-center p-3 top-1/2  left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
                        <div className='text-2xl md:text-5xl font-sans  font-extralight pl-8 mx-6 my-2'>
                            Votre Agence de référence en immobilier de luxe
                        </div>
                        <div className='text-primary border-2 border-primary rounded-full p-5 tracking-widest absolute -top-3 -right-3  '>
                            HS
                        </div>
                        <div className='relative'>
                            <div className='transform md:text-3xl p-2 -translate-x-16 tracking-widest -translate-y-28 text-primary  absolute uppercase rotate-90 '>
                                The Home <br /> Signature 
                            </div> 
                        </div>
                        <button className='float-right'>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="M17 8l4 4m0 0l-4 4m4-4H3" />
                            </svg>
                        </button>
                    </div>

                    <div  className={` ${isOpen ? "fade-in":"hidden"}`}>
                        <button onClick={clickPrev} className='bg-black bg-opacity-20 hover:bg-opacity-75 p-3 absolute top-1/2 transform -translate-y-1/2 left-5'>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="M7 16l-4-4m0 0l4-4m-4 4h18" />
                            </svg>
                        </button>

                        <button onClick={clickNext} className='bg-black bg-opacity-20  hover:bg-opacity-75 p-3 absolute top-1/2 transform -translate-y-1/2 right-5'>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="M17 8l4 4m0 0l-4 4m4-4H3" />
                            </svg>
                        </button>
                    </div>
                    

                  
                </div>
            </div>

            {/* slider btn */}

            <div className='flex pt-4 items-center justify-center '>

                
                <button className={`h-[2px] rounded-full ${currentImage ==1 ? " bg-opacity-50":"bg-opacity-20"} w-full bg-white mx-2`}>

                </button>


                <button className={`h-[2px] rounded-full ${currentImage ==2 ? " bg-opacity-50":"bg-opacity-20"} w-full bg-white mx-2`}>

                </button>

                <button className={`h-[2px] rounded-full ${currentImage ==3 ? " bg-opacity-50":"bg-opacity-20"} w-full bg-white mx-2`}>

                </button>
                <button className={`h-[2px] rounded-full ${currentImage ==4 ? " bg-opacity-50":"bg-opacity-20"} w-full bg-white mx-2`}>

</button>


            </div>
           
      </div>
    
  )
}

export default Slider