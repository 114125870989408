import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

const Logo = ({logo}) => {
    return (
        <div className='w-20 h-20'>
            <a href="/">
                <img src={`/${logo}.png`} alt="logo " srcset="" />
            </a>
            
        </div>
    )
}

const Menu = ({openMenu,setOpenMenu,color}) => {
    return (
        <div className={`flex items-center text-${color}`}>
            <div className='px-3'>
                Search 
            </div>
            <div className='opacity-40'>
                EN 
            </div>
            <span className='px-1'>·</span>
            <div >
                FR 
            </div>
            
            <div onClick={()=>setOpenMenu(!openMenu)} className='px-3'>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-7" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h8m-8 6h16" />
                </svg>
            </div>
            
        </div>
    )
}

function Header() {
    const location = useLocation()

    const [isBlack, setIsBlack] = useState(false)
    const [openMenu, setOpenMenu] = useState(false)


    const path = location.pathname.split("/")

    useEffect(() => {
      if (path.length ==2 && path[1]=="1" || path.length ==3 && path[2]=="listing" ) {
          setIsBlack(true)
      }
 
    }, [path])
    

    return (

        path[1]==="admin" ?
        ""
        :
        <div className=''>
            <div className={`flex bg-black ${isBlack ? "text-white":"text-white"} fixed w-full z-40 text-xl justify-between items-center p-4`}>
                <Logo logo={isBlack ? 'logo_white':'logo_white'} />
                <Menu setOpenMenu={setOpenMenu} openMenu={openMenu}/>
            </div>

            {/* menu mobile */}
            <div className={openMenu ? "block ":" hidden "}>
        
                <div onClick={()=> setOpenMenu(false)} className='h-screen w-screen z-50 fixed bg-black bg-opacity-70'></div>
                <div className='fixed z-60 fadeInRight top-0 right-0 w-1/4 h-screen bg-black'>
    
                    <div className={`flex  ${isBlack ? "text-white":"text-black"}  text-xl justify-between items-center p-4`}>
                        <div className='w-20 h-20'>
                            
                        </div>
                        <Menu setOpenMenu={setOpenMenu} color="white" openMenu={openMenu}/>


                    </div>

                    <div className='text-white  h-full overflow-hidden text-center'>
                        <ul className='flex flex-col  justify-between '>
       
                            <li className='p-4'><a href="/products">Products</a>  </li>

                            <li className='p-4'><a href="/projects">Projects</a>  </li>

                            <li className='p-4'><a href="/contact-us">Contact us</a>  </li>
                            {/* <li className='p-4'><a href="/profile">Profile</a>  </li> */}
                        </ul>
                     
                    </div>
                    
                </div>
                    
            </div>

          

        </div>
    )
   
}

export default Header