import React from 'react'
import { useDispatch } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import { logout } from '../actions/userActions'

const sideBarData = [
  {label:"Dashboard",value:""},

  {label:"Products",value:"productlist"},
  {label:"Projects ",value:"projectlist"},

  {label:"Users",value:"userlist"},
  {label:"Appointments",value:"orderlist"}
]

function SideBar() {
    const location = useLocation()

    const dispatch  = useDispatch()

    const handleLogout = () => {
      dispatch(logout())
    }
    return (
        <div className="px-8 fixed ">
            <div className='flex relative items-center px-4 h-32'>
                <img src="/logo.png" className='w-24' alt="" />
            </div>
            <div className='flex flex-col'>
              {sideBarData.map(sideItem => <div key={sideItem.value} className={`p-2 hover:bg-opacity-80 ${location.pathname.split('/')[2] ===sideItem.value ? " border-l-4 border-primary":"" } `}>
                  <Link to={`/admin/${sideItem.value}`}>
                    {sideItem.label}
                  </Link>
              </div>)}
            
            </div>
            <div className='py-4'>
              <button onClick={handleLogout} className='p-2 bg-black text-white'>Logout </button>
            </div>
        </div>
        
    )
}

export default SideBar
