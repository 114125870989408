import React from 'react'


function Services() {
  return (
    <>
        <div  data-aos="fade-up" className=' min-h-screen flex flex-col justify-evenly  bg-black text-white '>
        
            <div className='md:flex '>

                <div className='md:w-1/2 text-clenetre'>
                    <div className='max-w-md mx-auto'>

                        <div className='text-center pb-20'>
                            <h1 className='font-bold '>The Home Signature</h1> 
                            <p className='text-xs'>
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat explicabo nulla fugiat dolore tempore nam recusandae minima. Tempore, inventore iure.
                            </p>
                        </div>

                        <div className=' relative mb-3'>
                            <div className='w-[2px] absolute bg-primary h-full'>
                                
                            </div>
                            <h1 className='text-2xl pl-4'>New home </h1>
                            
                        </div>

                        <div className='flex flex-col '>
                            <p className='pb-8'>
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Corrupti voluptatum nesciunt debitis quaerat cum veritatis consequatur porro dolorem tempora et.
                            </p>
                            <button className=' whitespace-wrap p-2 bg-primary text-white'>
                                Start Now
                            </button>
                        </div>
                    
                    </div>
                </div>
                <div className='md:w-1/2'>
                    <div className='p-8 relative'>
                        <div className='border border-primary p-2 relative'>
                            <img src="images/services/2.jpg" alt="" srcset="" />
                            <img src="images/services/1.jpg" className='absolute top-10 left-10' alt="" srcset="" />

                        </div>
                    </div>
                
                </div>

            </div>


        </div>
        <div  data-aos="fade-up" className=' min-h-screen flex flex-col justify-evenly  bg-black text-white '>
     
            <div  className='md:flex '>

                <div className='md:w-1/2 text-clenetre'>
                    <div className='max-w-md mx-auto'>

                        <div className='text-center pb-20'>
                            <h1 className='font-bold '>The Home Signature</h1> 
                            <p className='text-xs'>
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat explicabo nulla fugiat dolore tempore nam recusandae minima. Tempore, inventore iure.
                            </p>
                        </div>

                        <div className=' relative mb-3'>
                            <div className='w-[2px] absolute bg-primary h-full'>
                                
                            </div>
                            <h1 className='text-2xl pl-4'>For Sale</h1>
                            
                        </div>

                        <div className='flex flex-col '>
                            <p className='pb-8'>
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Corrupti voluptatum nesciunt debitis quaerat cum veritatis consequatur porro dolorem tempora et.
                            </p>
                            <button className=' whitespace-wrap p-2 bg-primary text-white'>
                                Sale Home 
                            </button>
                        </div>
                    
                    </div>
                </div>
                <div className='md:w-1/2'>
                    <div className='p-8 relative'>
                        <div className='border border-primary p-2 relative'>
                            <img src="images/services/3.jpg" alt="" srcset="" />
                            <img src="images/services/4.jpg" className='absolute top-10 left-10' alt="" srcset="" />

                        </div>
                    </div>
                
                </div>

            </div>

        </div>
    </>
  
  )
}

export default Services