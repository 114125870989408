import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { listProjectDetails } from '../actions/projectActions'
import Loader from '../components/Loader'
import Message from '../components/Message'

function ProjectDetail({match}) {
    
    const dispatch = useDispatch()

    const projectDetails = useSelector((state) => state.projectDetails)
    const { loading, error, project } = projectDetails
  
    useEffect(() => {
   
      if (!project._id || project._id !== match.params.id) {
        dispatch(listProjectDetails(match.params.id))
      }
    }, [dispatch, match])

  return (
    <>
    {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <div>

        <div className='min-h-screen bg-green-50   flex items-center justify-center bg-cover bg-center bg-no-repeat w-full' style={{backgroundImage:`url(${project.images?.length >0 && project.images[0].image})`}}>
                <div className='h-screen bg-black bg-opacity-60 w-screen z-30'>
                    
                </div>
                
                <div className='absolute overflow-hidden bg-theme text-white z-50 bg-opacity-70 max-w-md  p-3 bottom-20 text-2xl left-20 transform  '>
                    <div className='text-5xl font-sans  font-extralight m-2'>
                        {project.title}
                    </div>
                    <p className='opacity-70 italic font-thin font-sans'>
                        Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                    </p>
                    <div className='text-primary border-2 border-primary rounded-full p-5 tracking-widest absolute -top-3 -right-3  '>
                        HS
                    </div>
                
                </div> 
        </div>

        <div className='py-20 max-w-screen-lg mx-auto'>
                <h1 className=' font-semibold text-2xl'>
                   {project.title}
                </h1>
                <p>{project.country}, {project.city}</p>
                <div className='flex pt-10'>
                    <div className='md:w-1/2'>
                        <p>
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. A delectus ipsa debitis! Distinctio nemo facilis a qui voluptatibus amet earum dignissimos neque, aperiam, nesciunt accusantium? Perferendis sed quo et? Perferendis?
                        </p>
                        <p className='pt-4'>
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. A delectus ipsa debitis! Distinctio nemo facilis a qui voluptatibus amet earum dignissimos neque, aperiam, nesciunt accusantium? Perferendis sed quo et? Perferendis?
                        </p>
                        <p className='pt-4'>
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. A delectus ipsa debitis! Distinctio nemo facilis a qui voluptatibus amet earum dignissimos neque, aperiam, nesciunt accusantium? Perferendis sed quo et? Perferendis?
                        </p>
                    </div>
                    <div className='md:w-1/2 font-sans font-thin pl-3'>
                        <ul>
                            <li className='p-3 border-black border-b border-t'>
                                <b>lorem</b> : Lorem ipsum dolor sit amet.
                            </li>
                            <li className='p-3 border-black border-b'>
                                <b>lorem</b> : Lorem ipsum dolor sit amet.
                            </li>
                            <li className='p-3 border-black border-b'>
                                <b>lorem</b> : Lorem ipsum dolor sit amet.
                            </li>
                            <li className='p-3 border-black border-b'>
                                <b>lorem</b> : Lorem ipsum dolor sit amet.
                            </li>
                        </ul>

                    </div>

                </div>
        </div>

        <div className='flex py-20'>
            <div className='w-1/2'>
                <img src={project.images?.length >1 && project?.images[1].image} alt="" srcset="" />
            </div>
            <div className='w-1/2'>
                <img src={project.images?.length >2 && project?.images[2].image} alt="" srcset="" />

            </div>

        </div>

        <div className='py-6 max-w-xl mx-auto text-center'>
            <p className='uppercase text-sm'>Lorem, ipsum.</p>

            <h1 className='text-2xl '>
                The lighting design picks up on the new square functions and combines them to create an urban space that can be experienced.
            </h1>

        </div>

        <div className=''>
            <img src={project.images?.length >3 && project?.images[3].image} alt="" />
        </div>

        <div className='text-white bg-black flex justify-center text-center items-center py-20'>
            <div>
                <h1 className='text-4xl pb-4 '>Get in touch </h1>
                <p>We look forward to realise extraordinary projects with you.</p>

                <div className='pt-4 flex flex-col md:flex-row items-center gap-2'>

                    <div className='w-44 p-2 border'>
                        HS contacts
                    </div>
                    <div className='w-44 p-2 border'>
                        Email us
                    </div>

                </div>

            </div>
        </div>

        
        </div>
      )}
    </>
  )
}

export default ProjectDetail