import React from 'react'

function ContactUs() {
  return (
    <div className='pt-32 flex justify-center items-center min-h-screen bg-cover bg-center' style={{backgroundImage:'url(/images/slider/1.jpg)'}}>
        <div className='bg-white bg-opacity-70 w-96 p-1 rounded-md shadow-md'>
            <div className='border p-4 rounded-md border-primary'>
        
                <div className='pb-4 pt-6'>
                    <h1 className='text-2xl text-center '>
                        Get In Touch 
                    </h1>
                </div>
                <div className='bg-white border'>
                    <input type="text" className='p-2 w-full outline-none bg-transparent border-none' name="c_fullname" placeholder='Full name' id="" /> 
                </div>
                <div className='bg-white mt-3 border'>
                    <input type="email" className='p-2 w-full outline-none bg-transparent border-none' name="c_email" placeholder='Email' id="" /> 
                </div>
                <div className='bg-white mt-3 border'>
                    <textarea type="text" rows={"5"} className='p-2 w-full outline-none bg-transparent border-none' name="c_email" placeholder='Your question !' id="" /> 
                </div>


                <div className='pt-4'>
                    <button className='p-2 bg-black w-full text-white'>Get In touch</button>
                </div>
            </div>
        </div>

    </div>
  )
}

export default ContactUs