import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../../components/Message'
import Loader from '../../components/Loader'
import { addOptionToProduct, deleteImage, deleteOption, listProductDetails, updateProduct } from '../../actions/productActions'
import { PRODUCT_UPDATE_RESET } from '../../constants/productConstants'
import AdminLayout from './AdminLayout'
import Photos from '../../components/Photos'

const ProductEditScreen = ({ match, history }) => {
  const productId = match.params.id
  const [images, setImages] = useState([])
  const [option, setOption] = useState({
    name:'',
    value:''
  })
  const [name, setName] = useState('')
  const [address, setAddress] = useState('')
  const [reference, setReference] = useState('')

  const [price, setPrice] = useState(0)
  const [type, settype] = useState('')
  const [category, setCategory] = useState('')
  const [countInStock, setCountInStock] = useState(0)
  const [numRooms, setNumRooms] = useState(0)
  const [description, setDescription] = useState('')
  const [city, setCity] = useState("")
  const dispatch = useDispatch()

  const productDetails = useSelector((state) => state.productDetails)
  const { loading, error, product } = productDetails

  const productUpdate = useSelector((state) => state.productUpdate)
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = productUpdate

  useEffect(() => {
    if (successUpdate) {
      dispatch({ type: PRODUCT_UPDATE_RESET })
      history.push('/admin/productlist')
    } else {
      if (!product.name || product._id !== productId) {
        dispatch(listProductDetails(productId))
      } else {
        setName(product.name)
        setPrice(product.price)
        setAddress(product.address)
        setCity(product.city)
        setReference(product.reference)
        settype(product.type)
        setCategory(product.category)
        setCountInStock(product.countInStock)
        setDescription(product.description)
      }
    }
  }, [dispatch, history, productId, product, successUpdate])


  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(
      updateProduct({
        _id: productId,
        name,
        price,
        address,
        city,
        numRooms,
        reference,
        type,
        category,
        description,
        countInStock,
      },images)
    )
  }


  const handleAddNewOption = (e) => {
    e.preventDefault()
    dispatch(addOptionToProduct(productId,option))
  }


  const handleDeleteOption = (id) => (ev) => {
    if (window.confirm('Are you sure')) {
      dispatch(deleteOption(productId,id)) 
    }
  }
  
  return (
    <>
      <AdminLayout>
        <div className="flex ">
          <div className="w-full pl-1">
            <h1 className='text-xl font-bold py-2'>Edit Product</h1>
            {loadingUpdate && <Loader />}
            {errorUpdate && <Message variant='danger'>{errorUpdate}</Message>}
            {loading ? (
              <Loader />
            ) : error ? (
              <Message variant='danger'>{error}</Message>
            ) : (
              <form>

                <Photos deleteImage={deleteImage} images={product.images} id={productId} setImages={setImages} />
                <div className='p-4 border mt-3 rounded-md '>
                  <div className=' my-2 '>
                    <div>
                        <label className=''>Type</label>
                    </div>
                    <div>

                    </div>
                    <div className='border'>
                        <select 
                          required
                          className='p-2 w-full outline-none bg-transparent'
                          value={type}  onChange={(e) => settype(e.target.value)} name="type" id="type">
                          <option value="For Sale">For Sale</option>
                          <option value="Apartments">For Rent</option>
                          <option value="New Home">New Home</option>

                        </select>
                    </div>
                    

                  </div>

                  <div className=' my-2 '>
                    <div className='pb-1 pl-1'>
                      <label className=' '>Name</label>
                    </div>
                    <div className='border rounded-md'>
                      <input
                        className='p-2  w-full outline-none bg-transparent'
                        type='text'
                        placeholder='Enter name'
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />                    
                    </div>
                   
                  </div>

                  <div className='my-2 '>
                    <div className='pb-1 pl-1'>
                      <label className=' '>Reference</label>
                    </div>
                    <div className='border rounded-md'>
                      <input
                        className='p-2 w-full outline-none bg-transparent'
                        type='text'
                        placeholder='Enter Reference'
                        value={reference}
                        onChange={(e) => setReference(e.target.value)}
                      />                    
                    </div>
                   
                  </div>

                  <div className=' my-2 '>
                    <div>
                      <label className='text-gray-500 '>Price</label>
                    </div>
                    <div className='border rounded-md'>
                      <input
                        className='p-2 w-full outline-none bg-transparent'
                        type='number'
                        placeholder='Enter price'
                        value={price}
                        onChange={(e) => setPrice(e.target.value)}
                      />
                    </div>
                   
                  </div>

                  <div className=' my-2 '>
                    <label className='  '>Category</label>
                    <div className='rounded-md border'>
                      <select
                        className='p-2 w-full outline-none bg-transparent'
                        value={category}  onChange={(e) => setCategory(e.target.value)} name="category" id="category">
                        <option value="Villas">Villas</option>
                        <option value="Apartments">Apartments</option>
                      </select>
                    </div>
                    
                  </div>
                  {/* number of rooms */}

                  <div className='my-2 '>
                    <label className=''>Number of Rooms </label>
                    <div className='border rounded-md '>
                      <input
                        type='number'
                        className='p-2 w-full outline-none bg-transparent'
                        placeholder='Enter number of rooms'
                        value={numRooms}
                        onChange={(e) => setNumRooms(e.target.value)}
                      />
                    </div>
                  </div>

                </div>
               
           

               <div className=' mt-2 border rounded-md p-4'>
                  <h1>Address</h1>
                  <div className=' my-2 '>
                    <div>
                      <label className=' '>City</label>
                    </div>
                    <div className='border rounded-md'>

                      
                      <input
                        className='p-2 w-full outline-none bg-transparent'
                        type='text'
                        placeholder='Enter address'
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                      />
                    </div>
    
                  </div>
                  <div className='my-2 '>
                    <div className='pb-1 pl-1'>
                      <label className=''>Address</label>
                    </div>
                    <div className='border rounded-md '>
                      <textarea
                        className='p-2 w-full outline-none bg-transparent'
                        type='text'
                        rows={2}
                        placeholder='Enter address'
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                      />
                    </div>
                   
                  </div>

               </div>

             


{/* 
                <div className='border my-2 relative'>
                  <label className='absolute text-gray-500 top-2 left-2 '>Image</label>
                  <div className='pl-32 flex items-center justify-between'>
                  <input
                    type='text' 
                    className='p-2 w-full outline-none bg-transparent'
                    placeholder='Enter image url'
                    value={image}
                    onChange={(e) => setImage(e.target.value)}
                  />
                  <input required type='file' className='w-32' onChange={uploadFileHandler}
                    label="Choose File "
                  />
                  </div>
                  
                 
                  {uploading && <Loader />}
                </div>
                 */}
              
            {/* 
                <div className='border my-2 relative'>
                  <label className='absolute text-gray-500 top-2 left-2 '>Count In Stock</label>
                  <input
                    type='number'
                    className='p-2 w-full  pl-32 outline-none bg-transparent'
                    placeholder='Enter countInStock'
                    value={countInStock}
                    onChange={(e) => setCountInStock(e.target.value)}
                  />
                </div> */}

                <div className='border rounded-md mt-4 p-4'>
                  <div className='my-2 '>
                    <label className=''>Description</label>
                    <div className='border rounded-md'>
                      <textarea
                        rows={10}
                        className='p-2 w-full outline-none bg-transparent'
                        type='text'
                        placeholder='Enter description'
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                      />
                    </div>
                  
                  </div>

                </div>
               
              

                {/* options */}

                <div className='p-4 mt-4 border rounded-md'>
                  <h1 className='pb-2'>Options</h1>
                  <div className='flex flex-wrap gap-x-2 '>
                      { product.options && product.options.map((option,id) => <div className='p-2 border rounded-md relative' key={id}>
                         <div className='uppercase'>
                          {option.name} - {option.value}
                         </div>
                         <div onClick={handleDeleteOption(option._id)} className='absolute cursor-pointer z-50 -top-3 -right-2 flex items-center justify-center w-6 h-6 bg-gray-50 rounded-full ' >
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                          </div>
                      </div>) }
                  </div>
                  <div className='pt-4 '>
                    <div className='flex  items-center'>
                      <div className='border mr-2 w-32 flex items-center justify-between '>
                        <select value={option.name} onChange={(e)=> setOption({...option,name:e.target.value})} className='p-2  outline-none appearance-none bg-transparent'  name="" id="">
                          <option className="uppercase" hidden>Select</option>
                          
                          <option className="uppercase" value="bathrooms">BATHROOMS</option>
                          <option className="uppercase" value="BEDROOMS">BEDROOMS</option>
                          <option className="uppercase" value="GARAGES">GARAGES</option>
                          <option className="uppercase" value="SIZE">SIZE</option>
                        </select>
                        <div className=''>
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={1}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
                          </svg>
                        </div>
                      </div>

                      <div className='mr-2 border'>
                        <input type="number" value={option.value} onChange={(e)=> setOption({...option,value:e.target.value})} className='p-2  bg-transparent appearance-none outline-none ' placeholder='2'  />
                      </div>
                      <button onClick={handleAddNewOption} className='p-2 bg-primary text-white'>Add</button>
                      
                    </div>
                    
                 
                  </div>


                </div>
                
                <button onClick={submitHandler} className="rounded-md py-2 px-8 text-white  bg-primary">
                  Update
                </button>
              </form>
            )}
          </div>
        </div>
        </AdminLayout> 
     
    </>
  )
}

export default ProductEditScreen
