import React from 'react'
import { Link } from 'react-router-dom'


const Product = ({ product }) => {
  return (
    <div className="">
      <Link to={`/products/${product._id}`}>
          <div class="w-full fade-in lg:group-hover:scale-105 group-hover:shadow-me duration-300  aspect-square overflow-hidden  shadow-me" >
              <img src={product.images && product.images[0]?.image} alt="s" class=" duration-300 w-full h-full object-center object-cover " />
          </div>
      </Link>
      <p class="opacity-70  mt-3 uppercase font-mono tracking-widest text-md ">{product.name}</p>
      <div className='flex justify-between items-center'>
          <h3 class="font-600  pb-2 mt-1 text-sm tracking-wider  uppercase">{product.city}</h3>
          
          <h3 class="font-600  pb-2 mt-1 text-xl tracking-wider  uppercase">{product.price} MAD</h3>
      </div>
    </div>
  )
}

export default Product
