import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import FormContainer from '../components/FormContainer'
import { register } from '../actions/userActions'

const RegisterScreen = ({ location, history }) => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [message, setMessage] = useState(null)

  const dispatch = useDispatch()

  const userRegister = useSelector((state) => state.userRegister)
  const { loading, error, userInfo } = userRegister

  const redirect = location.search ? location.search.split('=')[1] : '/'

  useEffect(() => {
    if (userInfo) {
      history.push(redirect)
    }
  }, [history, userInfo, redirect])

  const submitHandler = (e) => {
    e.preventDefault()
    if (password !== confirmPassword) {
      setMessage('Passwords do not match')
    } else {
      dispatch(register(name, email, password))
    }
  }

  return (
    <div className="max-w-screen-sm  mx-auto pt-32 ">
      <div className="bg-white p-4  ">
        <h1>Sign Up</h1>
  
        {message && <Message >{message}</Message>}
        {error && <Message >{error}</Message>}
        {loading && <Loader />}
        <form onSubmit={submitHandler}>
          <div className="mt-2"  controlId='name'>
            <label>Name</label>
            <input
              className="py-2 px-2 w-full  outline-none bg-transparent border"
              type='text'
              placeholder='Enter name'
              value={name}
              onChange={(e) => setName(e.target.value)}
            ></input>
          </div>

          <div className="mt-2"  controlId='email'>
            <label>Email Address</label>
            <input
              type='email'
              className="py-2 px-2 w-full  outline-none bg-transparent border"
              placeholder='Enter email'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            ></input>
          </div>

          <div className="mt-2" controlId='password'>
            <label>Password</label>
            <input
              type='password'
              className="py-2 px-2 w-full  outline-none bg-transparent border"
              placeholder='Enter password'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            ></input>
          </div>

          <div className="mt-2"  controlId='confirmPassword'>
            <label>Confirm Password</label>
            <input
              type='password'
              className="py-2 px-2 w-full  outline-none bg-transparent border"
              placeholder='Confirm password'
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            ></input>
          </div>

          <button className="py-2 px-3 mt-2 bg-black text-gray-50" type='submit' variant='primary'>
            Register
          </button>
        </form>

        <div className='py-3'>
          <div>
            Have an Account?{' '}
            <Link className="text-blue-700 " to={redirect ? `/login?redirect=${redirect}` : '/login'}>
              Login
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RegisterScreen
