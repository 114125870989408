import React from 'react'
import { Link } from 'react-router-dom'

const Paginate = ({ pages, page, isAdmin = false, keyword = '' }) => {
  return (
   
    pages > 1 && (
      <div className='flex justify-end'>
        {[...Array(pages).keys()].map((x) => (
          <Link
            key={x + 1}
            to={
              !isAdmin
                ? keyword
                  ? `/products/search/${keyword}/page/${x + 1}`
                  : `/products/page/${x + 1}`
                : `/admin/productlist/${x + 1}`
            }
          >
            <div className={`p-2 rounded-md ml-2 mt-3 border ${x + 1 === page ? "bg-primary":""}`}>{x + 1}</div>
          </Link>
        ))}
      </div>
    )
  )
}

export default Paginate
