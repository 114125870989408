import React, { useRef, useState } from 'react'
import { useDispatch } from 'react-redux'

const Photo = ({file}) => {
    return (
        <div className='px-2'>
            <img src={ URL.createObjectURL(file)} className="w-32 h-32 object-cover rounded-md" alt="" />
        </div>
    )
}

function Photos({images,id, deleteImage,setImages}) {
    const [productImages, setProductImages] = useState([]);

   const dispatch = useDispatch()

    const handleDeleteImage = (imageId)=> (ev) => {
        if (window.confirm('Are you sure')) {
            dispatch(deleteImage(id,imageId)) 
        }
    }

    const handleProductFiles = (event) => {
           const data = [];
            for (let i = 0; i < event.target.files.length; i++) {
                data.push(event.target.files[i]);
            }
        setProductImages((old)=> [...old,...data]);
        setImages((old)=> [...old,...data])
    }

    const imageRef = useRef()


    return (
        <div className='border p-4 rounded-md'>
            <h1 className='text-xl'>Photos </h1>

            <div className='pt-4'>
                <div className='flex flex-wrap  '>
                    
                    {images?.map( item =>{
                        return ( <div key={item._id} className=" relative  px-2">
                                    <div onClick={handleDeleteImage(item._id)} className='absolute cursor-pointer z-50 -top-3 right-0 w-6 h-6 bg-gray-50 rounded-full ' >
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                        </svg>
                                    </div>
                                    <img className='w-32 h-32 object-cover rounded-md ' src={`${item?.image}`} />
                                </div>)
                            
                            }
                    )}
                    <div  className='hidden'>
                        <input type="file" ref={imageRef} className='hidden' onChange={handleProductFiles} multiple />
                    </div>
                
                    {productImages.map((item,id) => <Photo file={item} key={id} />)}
                    
                    <div onClick={() => imageRef.current.click()} className='w-32 h-32 border-2 border-dashed border-blue-400 object-cover rounded-md flex items-center justify-center cursor-pointer bg-gray-50'>
                            <span className='text-gray-400'>  Add Image </span>
                    </div>


                </div>
            </div>
        
        </div>
     
    )
}

export default Photos