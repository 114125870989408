import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import Meta from '../components/Meta'
import {
  listProductDetails,
  createProductReview,
} from '../actions/productActions'
import { PRODUCT_CREATE_REVIEW_RESET } from '../constants/productConstants'
import PopUp from '../components/PopUp'



const ProductOption = ({item}) => {
  return (
    // <div className='bg-black bg-opacity-20 rounded-md p-4'>
    //   {item.option} - {item.value}
    // </div>
    <div className='md:w-1/4 w-1/2 p-2 '>
      <div class="text-xs  items-center py-3 px-4 flex rounded-md  border ">
        <img class="w-12 pr-4" src={`/options/${item?.name?.toLowerCase()}.png`} />
        <div>
          <p class=" items-center uppercase opacity-60 font-mono text-2xs tracking-wider mr-auto inline-block"><span class="pt-px">{item.name}:</span></p><p class="ml-auto text-xs font-600 uppercase">{item.value}</p>
        </div>
      </div>
    </div>
   



  )
}

const ProductScreen = ({ history, match }) => {
  const [qty, setQty] = useState(1)
  const [rating, setRating] = useState(0)
  const [comment, setComment] = useState('')
  const [currentImageSlider, setCurrentImageSlider] = useState(0)
  const [openImageZoom, setOpenImageZoom] = useState(false)
  const [zoomedImage, setZoomedImage] = useState("")
  const dispatch = useDispatch()

  const productDetails = useSelector((state) => state.productDetails)
  const { loading, error, product } = productDetails

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const productReviewCreate = useSelector((state) => state.productReviewCreate)
  const {
    success: successProductReview,
    loading: loadingProductReview,
    error: errorProductReview,
  } = productReviewCreate

  useEffect(() => {
    if (successProductReview) {
      setRating(0)
      setComment('')
    }
    if (!product._id || product._id !== match.params.id) {
      dispatch(listProductDetails(match.params.id))
      dispatch({ type: PRODUCT_CREATE_REVIEW_RESET })
    }
  }, [dispatch, match, successProductReview])

  const sendMessage = () => {
    // history.push(`/appointment/${match.params.id}?qty=${qty}`)

    alert("ok")
  }

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(
      createProductReview(match.params.id, {
        rating,
        comment,
      })
    )
  }

  const zoomImage = (image) => (e) => {
    console.log(image)
  }

  return (
    <div className="pb-20">
    
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <>
          <Meta title={product.name} />
          <div className=" pb-20 " >
            <div className='bg-cover relative  bg-center bg-no-repeat' style={{minHeight:"85vh",backgroundImage:`url(${product.images && product.images[currentImageSlider]?.image})`}}>
              {/* <img  src={product.image} alt={product.name}  /> */}
              <div className='absolute left-1/2 transfrom -translate-x-1/2 bottom-5'>
                <div className='flex items-center '>
                  {[...Array(product.images && product.images.length).keys()].map(
                    (id)=>  <span onClick={()=> setCurrentImageSlider(id)} key={id} className={`w-10 mx-2 cursor-pointer h-[4px] ${currentImageSlider ===id ? "bg-black":"bg-gray-50"} rounded-full`}>
                    </span>
                  )}
                
               
                </div>
              </div>
            </div>
            <div className='max-w-screen-xl mx-auto px-2'>
              <div className='py-10'>
                <div className='flex flex-wrap '>
                  { product.options && product.options.map(item => <ProductOption item={item} />)}
                </div>
              </div>
              <div className="">
                <div className='md:flex justify-between '>
                  <div className='md:w-2/3 '>  
                    <h1 className="text-3xl font-mono pt-4">{product.name}</h1>
                    <div className='flex opacity-70 pt-2'>
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                        <path strokeLinecap="round" strokeLinejoin="round" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                      </svg>
                      <div className='px-3'>
                        Location : {product.location} 
                      </div>
                   
                    </div>
                    <div className="opacity-70 py-3 ">
                        Reference : {product.reference}
                    </div>
                  </div>
                  <div>  
                    <h1 className="text-3xl  font-mono pt-4">{product.price} MAD</h1>
                    <div className="text-gray-400 py-3 flex items-center ">
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                      </svg>
                      <div className='px-2'>
                          Add to favorite
                      </div>
                      
                      
                    </div>
                  </div>
                  
                </div>
                
                <div className='md:flex'>
                  <div className='md:w-2/3'>
                    {/* subtitle */}
                    <div className='flex items-center '>
                      <div className='px-10 py-6'>
                        <h1 className='text-black'>Description </h1>
                      </div>
                      <span className='w-full flex-grow bg-black h-[1px] '>

                      </span>
                    </div>

                    <p className="pt-3">
                      Lorem ipsum, dolor sit amet consectetur adipisicing elit. Vitae modi ipsum architecto eaque! Corporis non corrupti ullam, excepturi quas quia ea dolorem asperiores, numquam est aut, mollitia tenetur praesentium ex?
                    </p>
                    <p className="pt-3">
                    Beautiful, updated, ground level Co-op apartment in the desirable Bay Terrace neighborhood. This home features hardwood floors throughout, brand new bathrooms, newer EIK, modern front-load washer/dryer, full dining room, large living area, 3 spacious bedrooms and plenty of storage.
                    </p>
                    <p className='pt-3'>
                      Master bedroom includes both a standard closet and custom closet wall unit. Large windows face many directions for tons of natural light. Just steps away from QM2 express bus to Manhattan and local buses; only minutes from the LIRR.
                    </p>
                  </div>
                  <div className='md:w-1/3 py-6 md:py-0 md:pl-4 '>
                    
                  <form class="w-full shadow-md rounded-md p-4">
                    <div class="flex flex-wrap -mx-3 mb-6">
                     
                        <div class="w-full  px-3">
                            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                               Full Name
                            </label>
                            <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" type="text" placeholder="Doe" />
                        </div>
                    </div>
                    <div class="flex flex-wrap -mx-3 mb-6">
                        <div class="w-full px-3">
                        <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-password">
                            E-mail
                        </label>
                        <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="email" type="email" />
                        <p class="text-gray-600 text-xs italic">Some tips - as long as needed</p>
                        </div>
                    </div>
                    <div class="flex flex-wrap -mx-3 mb-6">
                        <div class="w-full px-3">
                        <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-password">
                            Message
                        </label>
                        <textarea class=" no-resize appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 h-48 resize-none" id="message"></textarea>
                        <p class="text-gray-600 text-xs italic">Re-size can be disabled by set by resize-none / resize-y / resize-x / resize</p>
                        </div>
                    </div>
                    <div class="md:flex md:items-center">
                        <div class="md:w-1/3">
                        <button class=" bg-black text-white hover:bg-opacity-70 focus:shadow-outline focus:outline-none  font-bold py-2 px-4 " type="button">
                            Send
                        </button>
                        </div>
                        <div class="md:w-2/3"></div>
                    </div>
                </form>
                  </div>

                  
                </div>

                <div className='flex '>
                  <div className='w-2/3'>
                    <div className='flex items-center '>
                      <div className='px-10 py-6'>
                        <h1 className='text-black'>Features </h1>
                      </div>
                      <span className='w-full flex-grow bg-black h-[1px] '>

                      </span>
                    </div>
                  </div>
       
                </div>

                <div>

                </div>
        
              
                  {/* <div className="pt-4">
                    <button onClick={()=> {}} className="w-full text-center py-2  border border-black  ">ACHETER MAINTENET</button>
                  </div> */}

              </div>
            </div>
        
           
            
            {/* gallery  */}

            {/* <div className='max-w-screen-xl mx-auto px-2'>
              <div className='md:columns-3 2xl:columns-4 gap-4 [column-fill:_balance] box-border mx-auto before:box-inherit after:box-inherit'>
               

                  {product?.images?.map(pr =>
                  <>
                    <div className='break-inside-avoid mb-4  '>
                      
                        <div className='border relative transition ease-in-out delay-150 hover:-translate-y-1 z-50 hover:scale-105  duration-300 '>
                            <img src={pr.image} className={"w-full rounded-md"} />
                            <div onClick={()=>{
                                    setZoomedImage(pr.image)
                                    setOpenImageZoom(true)}} className='absolute cursor-pointer bottom-3  rounded-full shadow-md drop-shadow-xl  right-3'>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={1}>
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0zM10 7v3m0 0v3m0-3h3m-3 0H7" />
                                    </svg>
                            </div>
                        </div>
                       
                        <div className='p-3'>
                            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Error debitis culpa sunt deleniti minus voluptas itaque quod accusantium similique,
                        </div>
                    </div>
 
                    </>
                    )}

                    

  
              </div>
            </div> */}
              <div className='max-w-screen-xl mx-auto px-2'>
              <div className='md:columns-3 2xl:columns-4 gap-4 [column-fill:_balance] box-border mx-auto before:box-inherit after:box-inherit'>
               

                  {product?.images?.map(pr =>
                  <>
                    <div className='break-inside-avoid mb-4  '>
                      
                        <div className='relative'>
                            <img src={pr.image} className={"w-full rounded-md"} />
                            <div onClick={()=>{
                                    setZoomedImage(pr.image)
                                    setOpenImageZoom(true)}} className='absolute cursor-pointer bottom-3  rounded-full shadow-md drop-shadow-xl  right-3'>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={1}>
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0zM10 7v3m0 0v3m0-3h3m-3 0H7" />
                                    </svg>
                            </div>
                        </div>
                       
                        <div className='p-3'>
                            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Error debitis culpa sunt deleniti minus voluptas itaque quod accusantium similique,
                        </div>
                    </div>
 
                    </>
                    )}

                    

  
              </div>
            </div>


            <PopUp isOpen={openImageZoom} setIsOpen={setOpenImageZoom} >
                <img src={zoomedImage} />
            </PopUp>
          </div>
         
         </>
      )} 
    </div>
  )
}

export default ProductScreen
